<template>
  <div>
    <com-mon>
      <div>
        <div class = "topbox flexRow">
          <img :src="imgurl+'/image/AppKfAppBiga06eacdd2x.png'" style="width:46px;height:46px" alt="">
          <div class = "titflexCol" style="margin-left:5px">
            <div class = "titlefont">微信客服</div>
            <div class = "contentfont" >
              <span>
                1个企业，可添加10个微信客服帐号，可以在微信内、外各个场景中接入不同帐号；给各帐号配置接待人员，
                用户发起咨询，接待人员即可回复消息，还有客服工具栏、升级服务等工具帮助企业做好服务。
              </span>
              <el-button type="text" style="margin:0px;padding:0px;font-size:12px" @click="jump(3)">查看微信客服使用教程</el-button>
            </div>
          </div>
        </div>
        <el-card style="margin-top:10px">
          <div class = "yellowbox" v-if="!message.IsCertification">
            <i class = "el-icon-warning" style="font-size:16px"></i>
            <span style="margin-left:5px">
              您的企业还未完成认证，仅可接待100位客户咨询，为不影响微信客服功能的正常使用，请前往企业微信后台，完成认证。
            </span>
            <el-button type="text" style="margin:0px;padding:0px" @click="jump(1)">前往验证</el-button>
            <el-button type="text" style="margin:0px 0px 0px 10px;padding:0px" @click="jump(2)">如何认证？</el-button>
          </div>
          <div class = "flexRow" style="margin-bottom:16px">
            <buttonPermissions :datas = "'serviceaccountpush'" style="margin-right:10px">
              <el-button type="primary" @click="edit({Id:0})">添加客服帐号</el-button>
            </buttonPermissions>
            <el-button :loading="btnloading" @click="synchronous">
              <span v-if="!btnloading">同步企微后台客服帐号</span>
              <span v-else>数据同步中...</span>
            </el-button>
          </div>
          <div>
            <el-table :data="tablelist" v-loading = "tableloading">
              <el-table-column prop="" label="客服帐号">
                <template slot-scope="scope">
                  <div class = "flexRow">
                    <img :src="scope.row.HeadUrlComplete" style="width:50px;height:50px;border-radius:3px" alt="">
                    <div class = "flexCol" style="margin-left:5px;flex:1">
                      <div class = "namefont">
                        <span>{{scope.row.Name}}</span>
                        <span style="color:#E6A23C">@{{message.CorpName}}</span>
                      </div>
                      <div style="color:#999999;line-height:1.5;margin-top:3px">
                        {{scope.row.OpenKfId}}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="接待人员" width="300">
                <template slot-scope="scope">
                  <div class = "fonthidden" v-if="scope.row.KfEmployeeNames">{{scope.row.KfEmployeeNames}}</div>
                  <div v-else>未设置</div>
                </template>
              </el-table-column>
              <el-table-column prop="KfLinkUrl" label="">
                <template slot="header">
                  <div class = "flexRow">
                    <span>客服链接</span>
                    <el-popover placement="top" trigger="hover">
                      <div style="max-width:300px">
                        商家可以复制该链接配置到微信内、外场景。点击对应客服帐号链接，会话将被分配给帐号下的接待人员。
                      </div>
                      <i slot="reference" class = "el-icon-question" style="margin-left:5px;color:#303133"></i>
                    </el-popover>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span>{{scope.row.KfLinkUrl}}</span>
                  <el-button class = "noborder" icon = "el-icon-document-copy" style="color:#409eff"
                  v-clipboard:copy="scope.row.KfLinkUrl" v-clipboard:success="onCopy" v-clipboard:error="onError"></el-button>
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作" width="100">
                <template slot-scope="scope">
                  <buttonPermissions :datas="'serviceaccountedit'" style="margin-right:10px">
                    <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'serviceaccountdel'" style="margin-right:10px">
                    <el-button type="text" style="color:#f56c6c" @click="del(scope.row)">删除</el-button>
                  </buttonPermissions>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
    </com-mon>
  </div>
</template>
<script>
import Commom from "@/views/customerServe/component/Common.vue"//引入组件
import buttonPermissions from '@/components/buttonPermissions';
import config from "@/config"
import{
  qyWeixinqyWeixinKfInit,
  qyWeixinKfAccountlist,
  qyWeixinKfAccountsync,
  qyWeixinKfAccountdelete
}from "@/api/sv3.0.0"
import {
  qyWeixinKfScenesConfigList,
  qyWeixinKfScenesConfigSave,
  qyWeixinKfAccountList
	} from '@/api/sv1.0.0.js'
export default {
  components:{
    buttonPermissions,
     "com-mon":Commom
  },
  data () {
    return {
      imgurl:config.IMG_BASE,
      message:{},
      tablelist:[],
      tableloading:false,
      btnloading:false,
    }
  },
  created () {
    this.gettablelist()
  },
  methods: {
    jump(e){
      if(e==1){
        window.open('https://work.weixin.qq.com/')
      }else if(e==2){
        window.open('https://jusnn6k8al.feishu.cn/docs/doccncb7Aa5aJv7I3f648ymg71c')
      }else if(e==3){
        window.open('https://jusnn6k8al.feishu.cn/docs/doccnnlJx8gxn9ZlDDAsF1m4f8I')
      }
    },
    onCopy(){
      this.$message.success('客服帐号链接复制成功')
    },
    onError(){
      this.$message.error('客服帐号链接复制失败')
    },
    async del(e){
      try{
        this.tableloading = true
        let list = []
        let msg = await e
        let res = await qyWeixinKfScenesConfigList()
        if(res.IsSuccess){
          list = res.Result.filter(v=>{
            return v.IsOpen
          }).map(v=>{
            return v.QyWeixinKfAccountId
          })
        }
        this.$confirm('删除后，不可恢复，且客户将无法向该帐号下的接待人员发起咨询，是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(this.tablelist.length<=1){
            this.$confirm('删除失败，至少保留1个客服帐号。若你不希望继续接入微信客服通道，可前往场景接入配置页，关闭所有已接入场景即可。', '提示', {
              confirmButtonText: '前往关闭',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({
                path:'/customerServe/WxCustomerServe/sceneaccess'
              })
            }).catch(() => {});
          }else if(list.includes(msg.Id)){
            this.$confirm('该客服帐号已经在场景中接入，请先前往场景接入页面，关闭对应场景。', {
              confirmButtonText: '前往关闭',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({
                path:'/customerServe/WxCustomerServe/sceneaccess'
              })
            }).catch(() => {});
          }else{
            this.todel(msg)
          }
        }).catch(() => {});
        
      }finally{
        this.tableloading = false
      }
    },
    async todel(e){
      try{
        this.tableloading = true
        let data = {
          Id:await e.Id
        }
        let res = await qyWeixinKfAccountdelete(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    edit(e){
      this.$router.push({
        path:'/customerServe/WxCustomerServe/edit',
        query:{
          Id:e.Id
        }
      })
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let msg = await qyWeixinqyWeixinKfInit()
        if(msg.IsSuccess){
          this.message = msg.Result
          // console.log(msg)
        }
        let res = await qyWeixinKfAccountlist()
        if(res.IsSuccess){
          this.tablelist = res.Result
        }
      }finally{
        this.tableloading = false
      }
    },
    async synchronous(){
      this.btnloading = true
      try{
        let res = await qyWeixinKfAccountsync()
        if(res.IsSuccess){
          this.$message.success(res.Message)
        }
      }finally{
        this.btnloading = false
        this.gettablelist()
      }
    },
  },
}
</script>

<style lang = "less" scoped>
  .topbox{
    padding:10px 20px;
    background:white;
    .titflexCol{
      display: flex;
      flex-direction: column;
    }
    .titlefont{
      font-size: 14px;
      color: #303133;
      /* line-height: 1.5; */
      font-weight: bold;
      margin-top: 2px;
    }
    .contentfont{
      font-size: 12px;
      color: #606266;
      /* line-height: 1.5; */
      margin-top: 10px;
    }
  }
  .fonthidden{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .namefont{
    word-break: break-all !important
  }
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flexCol{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .yellowbox{
    padding: 10px 20px;
    background: #FDF6EC;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    color:#E6A23C;
    font-size:14px;
    margin-bottom: 20px;
  }
  .noborder{
    border:none;
    margin: 0px;
    padding: 0px;
    margin-left:5px
  }
</style>